import BasePage from "pages/base_page"

let NewSessionPage

export default NewSessionPage = (function () {
  NewSessionPage = class NewSessionPage extends BasePage {
    static initClass() {
      this.prototype.events = {
        "submit form#sign-in-form": "_formSubmit",
      }
    }

    _formSubmit(e) {
      e.preventDefault()
      e.target.submit()
    }
  }

  NewSessionPage.initClass()
  return NewSessionPage
})()
