import PropTypes from "prop-types"
import i18n from "lib/i18n"
import React, { useEffect, useRef, useState } from "react"
import RecaptchaDisclosure from "components/Recaptcha/Disclosure"
import RecaptchaValidation from "components/Recaptcha/Validation"
import classNames from "classnames"
import { Button, Checkbox, Box, FormControlLabel, FormGroup, Typography } from "@theconversation/ui"

import { EmailField } from "./EmailField"
import { NewsletterSelector } from "./NewsletterSelector"
import PrivacyNotice from "components/NewsletterSubscriptionReactForm/PrivacyNotice"

import styles from "./styles.scss"

export function NewsletterMultiSubscriptionForm({
  csrfToken,
  fullWidth,
  locale,
  location,
  promoted,
  regionCode,
  recaptcha,
  newsletters,
  userEmail,
}) {
  const classes = [styles.container, fullWidth ? styles.fullWidth : styles.stacked]

  const [selected, setSelected] = useState(
    Object.fromEntries(newsletters.map((newsletter) => [newsletter.id, newsletter.selected])),
  )
  const [recaptchaToken, setRecaptchaToken] = useState()
  const [validEmail, setValidEmail] = useState(true)

  const formRef = useRef()
  const recaptchaRef = useRef()

  const [submitting, setSubmitting] = useState(false)

  //count selected newsletters
  const selectedCount = Object.values(selected).filter((value) => value).length
  const canSubmit = validEmail && selectedCount > 0

  const onVerify = (token) => {
    setRecaptchaToken(token)
    formRef.current.submit()
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!canSubmit) {
      return
    }

    setSubmitting(true)
  }

  useEffect(() => {
    if (submitting) {
      if (recaptcha.enabled) {
        recaptchaRef.current.execute()
      } else {
        formRef.current.submit()
      }
    }
  }, [submitting, recaptcha])

  return (
    <div className={classNames(classes)}>
      <Typography variant="h1" className="pb-4">
        {i18n.t("newsletters_multi.heading")}
      </Typography>

      <form
        method="POST"
        action={`/${regionCode}/newsletters/subscribe`}
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <RecaptchaValidation
          enabled={recaptcha.enabled}
          instance={recaptchaRef}
          locale={locale}
          onVerify={onVerify}
          siteKey={recaptcha.siteKey}
        />

        <input type="hidden" name="authenticity_token" defaultValue={csrfToken} />
        <input type="hidden" name="subscribe[recaptcha_token]" defaultValue={recaptchaToken} />
        <input type="hidden" name="subscribe[location]" defaultValue={location} />
        <input type="hidden" name="promoted" defaultValue={promoted} />

        <Box className="pb-3">
          <EmailField
            label={i18n.t("newsletters_multi.labels.email")}
            errorMessage={i18n.t("newsletters_multi.errors.invalid_email")}
            fullWidth={true}
            name="subscribe[email]"
            className={styles.input}
            userEmail={userEmail}
            handleValidEmail={setValidEmail}
            required
          />
        </Box>

        {newsletters.map((newsletter) => (
          <NewsletterSelector
            key={newsletter.id}
            name={`subscribe[newsletter_list_ids][]`}
            newsletter={newsletter}
            selected={selected}
            setSelected={setSelected}
          />
        ))}

        <RecaptchaDisclosure enabled={recaptcha.enabled} />

        <PrivacyNotice />

        <Button
          colour="primary"
          variant="contained"
          size="small"
          type="submit"
          disabled={!canSubmit}
          className={styles.button}
        >
          {i18n.t("newsletters_multi.buttons.subscribe")}
        </Button>
      </form>
    </div>
  )
}

NewsletterMultiSubscriptionForm.defaultProps = {
  fullWidth: false,
  newsletters: [],
  userEmail: "",
}

NewsletterMultiSubscriptionForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  promoted: PropTypes.string.isRequired,
  regionCode: PropTypes.string.isRequired,
  userEmail: PropTypes.string,
  newsletters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      blurb: PropTypes.string.isRequired,
      preview_url: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
  recaptcha: PropTypes.shape({
    enabled: PropTypes.bool.isRequired,
    siteKey: PropTypes.string.isRequired,
  }).isRequired,
}
